export const FETCH_BROADCAST_REQUEST = "FETCH_BROADCAST_REQUEST";
export const FETCH_BROADCAST_SUCCESS = "FETCH_BROADCAST_SUCCESS";
export const FETCH_BROADCAST_FAILURE = "FETCH_BROADCAST_FAILURE";
export const FETCH_BROADCAST_CLEAR = "FETCH_BROADCAST_CLEAR";
export const FETCH_BROADCAST_REFRESH_UPDATE = "FETCH_BROADCAST_REFRESH_UPDATE";
export const FETCH_BROADCAST_SEARCH_FILTER = "FETCH_BROADCAST_SEARCH_FILTER";
export const FETCH_BROADCAST_ADDITIONAL_DATA = "FETCH_BROADCAST_ADDITIONAL_DATA";
export const FETCH_BROADCAST_UPDATE = "FETCH_BROADCAST_UPDATE";
export const LAST_MESSAGE_UPDATE = "LAST_MESSAGE_UPDATE";
export const NAME_UPDATE = 'NAME_UPDATE'
export const DELETE_PARTICULAR_LIST = 'DELETE_PARTICULAR_LIST';
export const ADD_PARTICULAR_LIST = 'ADD_PARTICULAR_LIST';
export const FETCH_UPDATE_REQUIRED = 'FETCH_UPDATE_REQUIRED'

export const fetchBroadCastRequest = () => ({
  type: FETCH_BROADCAST_REQUEST
});

export const fetchBroadCastSuccess = (data) => ({
  type: FETCH_BROADCAST_SUCCESS,
  payload: data
});

export const fetchBroadCastLastMessage = (data) => ({
  type: LAST_MESSAGE_UPDATE,
  payload: data
});

export const fetchBroadCastFailure = (error) => ({
  type: FETCH_BROADCAST_FAILURE,
  payload: error
});

export const fetchBroadCastClear = (data) => ({
  type: FETCH_BROADCAST_CLEAR
});

export const fetchPsychicRefreshWithUpdate = (data) => ({
  type: FETCH_BROADCAST_REFRESH_UPDATE,
  payload: data
});

export const fetchPsychicFilterBasedOnSearch = (data) => ({
  type: FETCH_BROADCAST_SEARCH_FILTER,
  payload: data
});

export const fetchBroadCastUpdate = (data) => ({
  type: FETCH_BROADCAST_UPDATE,
  payload: data
});

export const fetchUpdateRequiredFn = (data) => ({
  type:FETCH_UPDATE_REQUIRED,
  payload:data
})

export const fetchBroadCastAdditionalData = (data) => ({
  type: FETCH_BROADCAST_ADDITIONAL_DATA,
  payload: data
});

export const fetchBroadCastDelete = (data) => ({
  type: DELETE_PARTICULAR_LIST,
  payload: data
})


export const fetchBoradCastParticularNameUpdate =(data) => ({
  type: NAME_UPDATE,
  payload: data
});