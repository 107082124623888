import moment from "moment";
import { getActivePlan } from "../../Helpers/Chat/ChatHelper";
import mixpanel, { mixpanelCatchAsync } from "./MixpanelConfig";
import Store from "../../Store";
import { getFromLocalStorageAndDecrypt } from "../WebChat/WebChatEncryptDecrypt";
import { showConfrence } from "../../Actions/CallAction";

export const createConsumerProfile = mixpanelCatchAsync((userDetails) => {
  mixpanel.identify(userDetails.userId);

  mixpanel.people.set({
    $name: userDetails.nickName,
    consumer_username: userDetails.username,
    consumer_bio: userDetails.status,
    phone_number: userDetails.mobileNumber,
    subscription_status: "Free Member",
    member_role: userDetails.is_psychic === "1" ? ["Advisor", "Consumer"] : ["Consumer"],
    $email: userDetails.email,
    profile_picture_added_flag: userDetails.image ? true : false,
    member_joining_date: new Date().toISOString(),
    $user_id: userDetails.userId,
    user_id: userDetails.userId
  });

  mixpanel.track("Consumer Profile Created", {
    $user_id: userDetails.userId
  });
});

export const updateConsumerProfile = mixpanelCatchAsync(async (userDetails) => {
  const subscription = await getActivePlan();
  mixpanel.identify(userDetails.userId);
  mixpanel.people.set({
    $name: userDetails.nickName,
    consumer_username: userDetails.username,
    consumer_bio: userDetails.status,
    phone_number: userDetails.mobileNumber,
    subscription_status: subscription.data.activePlan === null ? "Free Member" : "Subscriber",
    member_role: userDetails.is_psychic === "1" ? ["Advisor", "Consumer"] : ["Consumer"],
    $email: userDetails.email,
    profile_picture_added_flag: userDetails.image ? true : false,
    $user_id: userDetails.userId,
    user_id: userDetails.userId
  });
});

export const addedFunds = mixpanelCatchAsync((details) => {
  const {
    user_id,
    fund_added_value,
    fund_added_origin,
    fund_added_date,
    bonus_added_flag,
    bonus_added_type,
    bonus_added_value,
    payment_method
  } = details;
  mixpanel.track("Consumer Added Funds", {
    $user_id: user_id,
    user_id:user_id,
    fund_added_value,
    fund_added_origin,
    fund_added_date,
    bonus_added_flag,
    bonus_added_type,
    bonus_added_value,
    payment_method
  });
});

export const bookedAppointments = mixpanelCatchAsync((details) => {
  const {
    reading_channel,
    appointment_origin,
    appointment_id,
    user_id,
    appointment_is_recurring_flag,
    reading_type,
    advisor_id,
    appointment_value,
    appointment_duration,
    appointment_scheduled_date,
    special_request_flag = false,
  } = details;

  mixpanel.track("Consumer Booked Appointment", {
    reading_channel,
    appointment_origin,
    appointment_id,
    $user_id: user_id,
    user_id:user_id,
    appointment_is_recurring_flag,
    reading_type,
    advisor_id,
    appointment_value,
    appointment_duration,
    appointment_scheduled_date: moment(appointment_scheduled_date).utc().format(),
    appointment_made_date: new Date().toISOString(),
    special_request_flag: special_request_flag
    // bonus_amount_withheld,
    // bonus_withheld_flag
  });
});

export const chatReadingCompeted = mixpanelCatchAsync((details) => {
  mixpanel.track("Chat Reading Completed", details)
});

export const audioVideoCallEnd = mixpanelCatchAsync((details) => {
  mixpanel.track("Audio Video Reading Completed", details);
});

export const advisorProfileCreate = mixpanelCatchAsync((obj) => {
  mixpanel.track("Advisor Profile Created", obj);
  // join data
  //create page
  // how can i get join date
});

export const advisorSetAvailability = mixpanelCatchAsync((obj) => {
  mixpanel.track("Advisor Sets Availability", obj);
});

export const braodCastMessageSend = mixpanelCatchAsync((obj) => {
  mixpanel.track("Advisor Broadcasted", obj);
});
